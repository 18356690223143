import * as React from "react";
import "../styles/main.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { motion } from "framer-motion";
import NavMobile from "../components/NavMobile";
import { SEO } from "../components/seo";
const NotFoundPage = () => {
  return (
    <>
      <NavMobile />
      <Header isHome={false} />
      <main>
        <section className="section">
          <div className="section-wrapper">
            <div className="text-container">
              <motion.h1
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="title"
              >
                404
              </motion.h1>
              <motion.p
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                Strona nie istnieje.
              </motion.p>
            </div>
          </div>
        </section>
      </main>
      <Footer isHome={false} />
    </>
  );
};

export default NotFoundPage;
export const Head = () => <SEO title="Miladent - 404" />;
